import { type FetchStateOptions } from '@base/composables/useAsyncCache'

export function useDistributionFee() {
  const store = useDistributionFeeStore()
  const defaultOptions: FetchStateOptions = {
    interval: Number.MAX_VALUE,
  }

  const { state, refresh, isLoading, error } = store.fetchDistributionFeesState(
    [],
    {
      ...defaultOptions,
    },
  )

  return {
    state,
    refresh,
    isLoading,
    error,
  }
}
